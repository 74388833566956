// NOTE: This file is side-effects central, not ideal but it's a result of:
// 1. jQuery being just truly awful
// 2. Interoperability with our own legacy Sprockets code (this should be gradually replaced)

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'whatwg-fetch' // window.fetch polyfill
import 'whatwg-url' // URL and URLSearchParams

// Side effects on jQuery
import 'jquery-ui'
import 'jquery-ui/ui/widgets/autocomplete'
import 'jquery-ujs'
import '@claviska/jquery-minicolors'

// CSS (Webpacked)
import 'jquery-ui/themes/base/core.css'
import 'jquery-ui/themes/base/menu.css'
import 'jquery-ui/themes/base/autocomplete.css'
import 'jquery-ui/themes/base/theme.css'

import '@claviska/jquery-minicolors/jquery.minicolors.css'

import '../styles/tags.css'

import * as notifications from '../src/notifications'
import * as fileUploaders from '../src/uploaders'

$.minicolors.defaults = $.extend($.minicolors.defaults, {
	theme: 'bootstrap'
});

window.snaploader = {
	...window.snaploader,
	fileUploaders,
	notifications,
}
