import { showError } from '../notifications'

const nextId = (function() {
	let counter = 0

	return function() {
		return (new Date().getTime() + counter++)
	}
}())

export function createUploader(attribute, associationAttribute, presign, previewContainer, buildUploadingElement, buildPreviewElement) {
	const metadata = new Map()
	const uploadingElements = new Map()

	const $previewContainer = $(previewContainer)

	return {
		add: function(event, data) {
			return data.process().then(() => {
				const file = data.files[0]

				presign(file).then(presignData => {
					const {fields, method, url} = presignData

					data.formData = fields
					data.url = url
					data.type = method.toUpperCase()
					data.dataType = 'text'

					const key = fields.key.split('/').splice(1).join('/')

					metadata.set(file, {
						shrineData: {
							id: key,
							storage: 'cache',
							metadata: {
								size: file.size,
								filename: file.name,
								mime_type: file.type,
							},
						},
						url
					})

					const $uploading = $(buildUploadingElement())

					$uploading.find('.cancel').on('click', () => {
						$uploading.remove()
					})

					$previewContainer.append($uploading)
					uploadingElements.set(file, $uploading[0])

					data.submit()
				}).catch(e => {
					console.error('Upload presign failed', e)
					data.abort()
				})
			})
		},
		done: function(event, data) {
			const response = data.jqXHR
			const file = data.files[0]

			const fileMetadata = metadata.get(file)

			const associationId = associationAttribute ? nextId() : null

			const fileData = JSON.stringify(fileMetadata.shrineData)
			const previewUrl = response.getResponseHeader('Location')
			const previewAlt = file.name

			previewContainer.removeChild(uploadingElements.get(file))

			const $preview = $(buildPreviewElement(attribute, associationId, associationAttribute, fileData, previewUrl, previewAlt))

			$preview.find('.cancel').on('click', () => {
				$preview.remove()
			})

			$previewContainer.append($preview)
			uploadingElements.delete(file)
		},
		fail: function(event, data) {
			const file = data.files[0]

			previewContainer.removeChild(uploadingElements.get(file))
			uploadingElements.delete(file)

			showError(null, `Error uploading image "${file.name}": ${file.error}`)
		}
	}
}
