// Note: PNotify's NPM package is real wack. So we're using webpack aliases to make these imports somewhat sane.
import PNotify from 'pnotify'
import 'pnotify/buttons' // Side effects on PNotify
import 'pnotify/callbacks'

const commonOptions = {
	styling: 'bootstrap3',
	icons: 'bootstrap3',
	history: false,
	delay: 5000,
	stack: {
		dir1: 'down',
		dir2: 'right',
		push: 'top',
		spacing1: 0,
		spacing2: 0
	},
	modules: {
		Callbacks: {
			beforeOpen: (pnotify) => {
				pnotify.refs.elem.style.left = ((document.body.clientWidth - parseInt(pnotify.refs.container.style.width)) / 2) + 'px';
				pnotify.refs.elem.style.top = '70px';
			},
		},
	}
}

function showNotification(options = {}) {
	return PNotify.alert({
		...commonOptions,
		...options,
	})
}

function showError(title, message) {
	return showNotification({
		type: 'error',
		title: title || false,
		text: message,
	})
}

function showInfo(title, message, sticky) {
	return showNotification({
		hide: !sticky,
		title: title,
		text: message,
		type: 'info',
	})
}


export {
	showNotification,
	showError,
	showInfo,
}
