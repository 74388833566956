// Side effects on jQuery
import 'blueimp-file-upload'
import 'blueimp-file-upload/css/jquery.fileupload.css'

import * as document from './document'
import * as images from './images'

export {
	document,
	images,
}
