import { createUploader } from './shrine-jquery-file-uploader'
import { presignFile } from './s3'

const ALLOWED_FILE_TYPES_REGEX = /^image\/(jpg|jpeg|png|gif)$/

// NOTE: Would *love* to replace jquery-file-upload with something like Uppy, but it's presently pretty heavily integrated with our UI.

const buildDefaultUploadingElement = () => {
	const container = document.createElement('div')
	container.className = 'preview-container image-container fade in'

	const preview = document.createElement('div')
	preview.className = 'preview'

	const imageWrapper = document.createElement('span')
	imageWrapper.className = 'fade'

	preview.appendChild(imageWrapper)

	const cancelButtonWrapper = document.createElement('span')
	cancelButtonWrapper.className = 'cancel'

	const cancelButton = document.createElement('button')
	cancelButton.className = 'close'
	cancelButton.innerHTML = '&times;'

	cancelButtonWrapper.appendChild(cancelButton)

	const startButtonWrapper = document.createElement('span')
	startButtonWrapper.className = 'start hidden'
	startButtonWrapper.appendChild(document.createElement('button'))

	const progressBarWrapper = document.createElement('div')
	progressBarWrapper.className = 'upload-file-progress progress progress-warning progress-striped active'
	progressBarWrapper.setAttribute('role', 'progressbar')
	progressBarWrapper.setAttribute('aria-valuemin', '0')
	progressBarWrapper.setAttribute('aria-valuemax', '100')
	progressBarWrapper.setAttribute('aria-valuenow', '0')

	const progressBar = document.createElement('div')
	progressBar.className = 'bar'
	progressBar.style.width = '0'

	progressBarWrapper.appendChild(progressBar)

	container.appendChild(preview)
	container.appendChild(cancelButtonWrapper)
	container.appendChild(startButtonWrapper)
	container.appendChild(progressBarWrapper)

	return container
}

const buildDefaultPreviewElement = (attribute, associationId, associationAttribute, fileData, previewUrl, previewAlt) => {
	const container = document.createElement('div')
	container.className = 'preview-container image-container fade in nested-fields'

	const preview = document.createElement('div')
	preview.className = 'preview'

	const imageWrapper = document.createElement('span')
	imageWrapper.className = 'fade in'

	const image = document.createElement('img')
	image.alt = previewAlt
	image.src = previewUrl

	imageWrapper.appendChild(image)
	preview.appendChild(imageWrapper)

	const input = document.createElement('input')
	input.type = 'hidden'
	input.name = associationId ? `${attribute}[${associationId}][${associationAttribute}]` : attribute
	input.value = fileData

	const cancelButtonWrapper = document.createElement('span')
	cancelButtonWrapper.className = 'cancel'

	const cancelButton = document.createElement('button')
	cancelButton.className = 'close'
	cancelButton.innerHTML = '&times;'

	cancelButtonWrapper.appendChild(cancelButton)

	container.appendChild(preview)
	container.appendChild(input)
	container.appendChild(cancelButtonWrapper)

	return container
}

/* options:
 * - buildUploadingElement, function
 * - buildPreviewElement, function
 * - dropZone, DOM element
 * - previewContainer, DOM element
 */
function init($uploadContainer, options) {
	options = options || {}

	const $fileInput = $uploadContainer.find('input.upload-input-field')
	const attribute = $fileInput.data('attribute')
	const associationAttribute = $fileInput.data('association-attribute')

	const {buildUploadingElement, buildPreviewElement, dropZone, previewContainer} = options

	const buildUploading = buildUploadingElement || buildDefaultUploadingElement
	const buildPreview = buildPreviewElement || buildDefaultPreviewElement

	const container = previewContainer || $uploadContainer.find('.uploaded-images')[0]
	const uploader = createUploader(attribute, associationAttribute, presignFile, container, buildUploading, buildPreview)

	return $uploadContainer.fileupload({
		autoupload: true,
		dropZone: dropZone ? $(dropZone) : $uploadContainer.find('.drop-zone'),
		fileInput: $fileInput,
		acceptFileTypes: ALLOWED_FILE_TYPES_REGEX,
		previewSourceFileTypes: ALLOWED_FILE_TYPES_REGEX,
		maxNumberOfFiles: $fileInput.data('max-uploadable'),
		maxFileSize: 10 * 1024 * 1024,
		imageMaxWidth: 3840,
		imageMaxHeight: 2160,
		...uploader,
	})
}

export {
	init,
}
