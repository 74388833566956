export async function presignFile(file) {
	const presignResponse = await fetch(`/presign?name=${file.name}&type=${file.type}`, {
		credentials: 'include',
	})

	if (!presignResponse.ok) {
		throw new Error('Presign failed with status code: ' + presignResponse.status)
	}

	return await presignResponse.json()
}
